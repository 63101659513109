<!--
 * @Descripttion: 手机号输入框
 * @FilePath: /azg/src/views/ditui/register/components/PhoneField/index.vue
 * @Author: 张兴业
 * @Date: 2020-10-09 14:26:09
 * @LastEditors: 张兴业
 * @LastEditTime: 2021-04-13 17:46:48
-->
<template>
  <div class="phone_field">
    <img
      slot="label"
      src="@/assets/img/ditui/phone.png"
      alt=""
      class="icon left"
    />
    <van-field
      v-model="phone"
      name="phone"
      label-width="48px"
      clearable
      placeholder="请输入手机号"
      :class="phone ? 'phone' : 'phone_un'"
      @input="phoneChange"
      @clear="clear"
    />
  </div>
</template>

<script>
export default {
  props: ["value"],
  data() {
    return {
      phone: this.value
    };
  },
  methods: {
    phoneChange(value) {
      this.$emit("input", value);
    },
    clear() {
      this.$emit("input", "");
    }
  }
};
</script>

<style lang="less" scoped>
.phone_field {
  display: flex;
  flex-direction: row;
  // height: 48px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 13px;
  box-sizing: border-box;

  .icon {
    width: 24px;
    height: 24px;
    margin: auto 12px;
  }

  .phone {
    font-size: 14px;
    font-weight: 500;
    border-radius: 13px;
    padding: 11px 12px 10px 0;
  }

  .phone_un {
    font-size: 14px;
    font-weight: 400;
    border-radius: 13px;
    padding: 11px 12px 10px 0;
  }
}
</style>
